import React, {useState} from 'react'
import Waitcomp from './waitcomp'
import Formcomp from './formcomp'
import Errorcomp from './errorcomp'
import Okcomp from './okcomp'




const Test = () => {
    const [load, setLoad] = useState(false)
    const [test, setTest] = useState(false)
    const [txt, setTxt] = useState(false)
    const cambio = () =>{
        setLoad(!load)
    }

    const nulear = () =>{
        setTest(!test)
    }
    
    const errar = () =>{
        setTxt(!txt)
    }
    return (    
        
        <div>
            {!test ? (load ? <Waitcomp/> : <Formcomp/>): txt ? <Okcomp/> : <Errorcomp/>}
            <button onClick={cambio}>PROBAR</button>
            <button onClick={nulear}>evento null</button>
            <button onClick={errar}>error</button>
        </div>
        
    )
}

export default Test
