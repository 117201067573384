import React from 'react';
import './App.css';
import apacheimage from './images/CMC_logo_apaisado.png'
import {
  BrowserRouter as Router,
  Route,
  Routes
} from "react-router-dom"
import Home from './componentes/home';
import Ok from './componentes/ok';
import Error from './componentes/error';
import Test from './componentes/test';
import Helmet from 'react-helmet';



function App() {

  return (
    <div className="container">
      <Helmet>
        <meta charSet="utf-8" />
        <title>CM-Connector</title>
        <link rel="canonical" href="https://cm-connector.apachedigital.io/" />
        <meta name='desctiption' content='nombre de la pestaña' />
      </Helmet>
      <div className='logo'>
      <img src={apacheimage} width="300px" alt="Logo de apache" id="logoApache"/>
      </div>
      <div className="child">
    <Router>
    
      
      <Routes>
        <Route path="/" element={<Home/>} />
        <Route path="/ok" element={<Ok/>} />
        <Route path="/error" element={<Error/>} />
        <Route path="/test" element={<Test/>} />
      </Routes>
     
    </Router>
    </div>
    </div>
  );
}

export default App;

