import React from 'react'
import tic from '../images/ok_verde.png'



const Ok = () => {

    const back = () =>{
        window.location.replace("/")
    }

    return (
        <div className='okFather'>
            <div>
            <img src={tic} alt="gif animado de tic verde" id="tic"/>
            </div>
            <div className='okChild'>
            <h1 id='txtb'>El archivo se ha subido correctamente</h1>
            <label className="backOk" for="btnBack">
                Volver al inicio
            </label>
            <button id="btnBack" onClick={back}></button>
            </div>
        </div>
    )
}

export default Ok
