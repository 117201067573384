import React, { useState } from 'react'
import axios from 'axios';
import cloudimage from '../images/cloud-computing.png'
import Waitcomp from './waitcomp'
import err from '../images/error_rojo.png'
import tic from '../images/ok_verde.png'




const Home = () => {
  const [archivos, setArchivos] = useState(null);
  const [load, setLoad] = useState(false);
  const [msg, setMsg] = useState(null);
  const [error, setError] = useState(null);
  const [state, setState] = useState(false)
  const [resp, setResp] = useState(false)
  const [client, setClient] = useState('Apache')



  const back = () => {
    setLoad(!load)
    setState(!state)
  }

  const loader = () => {

    setLoad(!load)


  }

  const changeClient = (c) => {
    setClient(c.target.value);
  }


  const insertarArchivos = e => {
    setArchivos(e);
  }

  const subirArchivos = async () => {
    const f = new FormData()

    if (client == 'Sercotel') {
      for (let index = 0; index < archivos.length; index++) { f.append("files", archivos[index]); } await
        axios.post("https://martech-api.apachedigital.io/cm_sercotel_process_api", f, {
          headers:
            { 'Access-Control-Allow-Origin': '*', 'content-type': 'application/x-www-form-urlencoded' }
        }).then(response => {
          if (response.data.codigo !== 1 && response.data.codigo !== 3) {
            //window.location.replace('/error')
            setMsg(response.data.msg)
            setError(response.data.error.error.message)
            setState(!state)
          } else if (response.data.codigo !== 1 && response.data.codigo !== 0) {
            setMsg(response.data.msg)
            setError("")
            setState(!state)
          } else {
            setResp(!resp)
            setState(!state)
          }
        }).catch(error => {
          console.log(error);
        })
    } else if (client == 'IFEMA') {
      for (let index = 0; index < archivos.length; index++) { f.append("files", archivos[index]); } await
        axios.post("https://martech-api.apachedigital.io/cm_ifema_process_api", f, {
          headers:
            { 'Access-Control-Allow-Origin': '*', 'content-type': 'application/x-www-form-urlencoded' }
        }).then(response => {
          if (response.data.codigo !== 1 && response.data.codigo !== 3) {
            //window.location.replace('/error')
            setMsg(response.data.msg)
            setError(response.data.error.error.message)
            setState(!state)
          } else if (response.data.codigo !== 1 && response.data.codigo !== 0) {
            setMsg(response.data.msg)
            setError("")
            setState(!state)
          } else {
            setResp(!resp)
            setState(!state)
          }
        }).catch(error => {
          console.log(error);
        })
    } else {
      for (let index = 0; index < archivos.length; index++) { f.append("files", archivos[index]); } await
        axios.post("https://martech-api.apachedigital.io/cm_process_api", f, {
          headers:
            { 'Access-Control-Allow-Origin': '*', 'content-type': 'application/x-www-form-urlencoded' }
        }).then(response => {
          if (response.data.codigo !== 1 && response.data.codigo !== 3) {
            //window.location.replace('/error')
            setMsg(response.data.msg)
            setError(response.data.error.error.message)
            setState(!state)
          } else if (response.data.codigo !== 1 && response.data.codigo !== 0) {
            setMsg(response.data.msg)
            setError("")
            setState(!state)
          } else {
            setResp(!resp)
            setState(!state)
          }
        }).catch(error => {
          console.log(error);
        })

    }
  }

  return (
    <div className='padre'>
      {!state ? (load ?
        <Waitcomp /> : <>
          <div className='clientDiv'>
            <p className='textClient'>Elige un cliente: </p>
            <select name="" id="" value={client} onChange={changeClient} className='selector'>
              <option value="Apache" selected>Apache</option>
              <option value="Sercotel" >Sercotel</option>
              <option value="IFEMA" >IFEMA</option>
            </select>
          </div>
          <input type="file" className="upload-box" name="files" id="file" accept=".csv" multiple
            onChange={(e) => insertarArchivos(e.target.files)} />

          <label for="subir" className="upfile">Subir archivo &nbsp;
            <img src={cloudimage} width="40px" alt="subir archivo" />
          </label>

          <button className="btn" id="subir" onClick={() => { subirArchivos(); loader() }}>Insertar archivos</button>
        </>) : !resp ? <>
          <div>
            <img src={err} alt="gif animado de error rojo" id="err" />
            <h1 id='txta'>Ha ocurrido el siguiente error: {msg} {error}</h1>
            <label className="backErr" for="btnBack" onClick={back}>
              Volver al inicio
            </label>
            <button id="btnBack"></button>
          </div>
        </> : <>
        <div>
          <img src={tic} alt="gif animado de tic verde" id="tic" />
          <h1 id='txtb'>El archivo se ha subido correctamente</h1>
          <label className="backOk" for="btnBack">
            Volver al inicio
          </label>
          <button id="btnBack" onClick={back}></button>
        </div>
      </>}

    </div>


  )

}

export default Home