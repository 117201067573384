import React from 'react'
import err from '../images/error_rojo.png'


const Error = () => {

const back = () =>{
window.location.replace("/")
}

return (
<div className='okFather'>
    <div>
        <img src={err} alt="gif animado de error rojo" id="err" />
    </div>
    <div className='okChild'>
        <h1 id='txta'>Ha ocurrido un error, el archivo no se ha subido</h1>
        <label className="backErr" for="btnBack">
            Volver al inicio
        </label>
        <button id="btnBack" onClick={back}></button>
    </div>

</div>
)
}

export default Error