import React, { Component } from 'react'
import ReactLoading from 'react-loading'

export default class Waitcomp extends Component {
    render() {
        return (
            <div>
                <ReactLoading type={"bars"} color={"dodgerblue"} width={'200px'} height={'100px'} className={"wait"}/> 
                <h1 id="txtc">Cargando...</h1>
            </div>
        )
    }
}
