import React, { Component } from 'react'
import cloudimage from '../images/cloud-computing.png'

export default class Formcomp extends Component {



    render() {
        return (
            <div>
                <div>
      <input type="file" className="upload-box" name="files" id="file" accept=".csv" multiple/>

      <label for="subir" className="upfile">Subir archivo &nbsp;
        <img src={cloudimage} width="40px" alt="subir archivo" />
      </label>

      <button className="btn" id="subir" >Insertar archivos</button>
    </div>
            </div>
        )
    }
}
